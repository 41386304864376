'use strict'

###*
 # @ngdoc service
 # @name mundoUtils.factory:TranslationHandler

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'mundoTranslationHandlerFactory', [
    '$log'
    '$window'
    'mundoConfiguration'
    ($log, $window, mundoConfiguration) ->
      ran = false
      missing = {}
      clearMissingTranslations = () ->
        missing = {}
      showMissingTranslations = () ->
        $log.debug '====================================================='
        $log.debug 'Missing translations:'
        $log.debug 'Type `clearMissingTranslations()` to reset this list.'
        $log.debug '====================================================='

        for key in Object.keys(missing).sort((a, b) -> missing[b] - missing[a])
          $log.debug "#{('     ' + missing[key]).slice(-5)} | #{key}"

      return (translationID, uses) ->
        if mundoConfiguration.debug
          missing[translationID] ?= 0
          missing[translationID]++

          if not ran
            ran = true
            $window.showMissingTranslations = showMissingTranslations
            $window.clearMissingTranslations = clearMissingTranslations
            $log.warn "Missing translations were detected. Type `showMissingTranslations()` for more information."

        # If the translation is missing, split the translation key on "." as delim
        # and return the last part
        return translationID.split('.').slice(-1)[0]

  ]
